define('ember-statecharts/computed', ['exports', 'xstate', 'ember-statecharts/utils/statechart'], function (exports, _xstate, _statechart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.statechart = exports.debugState = exports.matchesState = undefined;


  function matchesStateComputed(states) {
    return Ember.computed('statechart.currentState', function () {
      var _this = this;

      var _states = Ember.A(Ember.makeArray(states));

      return _states.any(function (state) {
        return (0, _xstate.matchesState)(state, Ember.get(_this, 'statechart.currentState.value'));
      });
    });
  }

  function debugState() {
    return Ember.computed('statechart.currentState', function () {
      return JSON.stringify(Ember.get(this, 'statechart.currentState.value'));
    });
  }

  function statechart(statechartConfig, statechartOptions) {
    return Ember.computed(function () {
      var statechart = new _statechart.default(Ember.assign(statechartConfig, { context: this }), statechartOptions);

      statechart.start();

      return statechart;
    });
  }

  exports.matchesState = matchesStateComputed;
  exports.debugState = debugState;
  exports.statechart = statechart;
});