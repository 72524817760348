define('ember-statecharts/utils/statechart', ['exports', 'xstate'], function (exports, _xstate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function noOp() {}

  var Interpreter = function () {
    function Interpreter(_ref) {
      var config = _ref.config,
          options = _ref.options,
          context = _ref.context;

      _classCallCheck(this, Interpreter);

      this.machine = (0, _xstate.Machine)(config, options);

      this.currentState = this.machine.initialState;

      this.context = context;
    }

    _createClass(Interpreter, [{
      key: 'send',
      value: function send(eventName, data) {
        var _this = this;

        var newState = this.machine.transition(this.currentState, { type: eventName, data: data }, this.context);

        this.currentState = newState;

        var actions = newState.actions;


        var _actions = actions.map(function (action) {
          return action.exec.bind(_this.context);
        });

        var chain = _actions.reduce(function (acc, action) {
          return acc.then(function () {
            return action(data);
          });
        }, Ember.RSVP.resolve());

        return chain;
      }
    }]);

    return Interpreter;
  }();

  var Statechart = function () {
    function Statechart(config, options) {
      var _this3 = this;

      _classCallCheck(this, Statechart);

      this.machine = (0, _xstate.Machine)(config, options);
      this.sendQueue = [];

      this.service = new Interpreter({
        config: {
          initial: 'initializing',
          states: {
            initializing: {
              on: {
                didInit: {
                  target: 'initialized',
                  actions: ['executeSendQueue']
                },
                send: {
                  target: 'initializing',
                  actions: ['enqueueSend']
                }
              }
            },
            initialized: {
              on: {
                send: {
                  target: 'initialized',
                  actions: ['executeSend']
                }
              }
            }
          }
        },
        options: {
          actions: {
            enqueueSend: function enqueueSend(sendData) {
              this.sendQueue.push(sendData);
              return this.initPromise;
            },
            executeSendQueue: function executeSendQueue() {
              var _this2 = this;

              var sends = this.sendQueue;
              var chain = sends.reduce(function (acc, _ref2) {
                var eventName = _ref2.eventName,
                    data = _ref2.data;

                return acc.then(function () {
                  return _this2.send(eventName, data);
                });
              }, Ember.RSVP.resolve());

              return chain.then(function () {
                _this2.resolveInit();
              });
            },
            executeSend: function executeSend(sendData) {
              return this._send(sendData);
            }
          }
        },
        context: this
      });

      this.didChangeState = config.didChangeState || noOp;
      this.context = config.context;

      this.initPromise = new Ember.RSVP.Promise(function (resolve, reject) {
        _this3.resolveInit = resolve;
        _this3.rejectInit = reject;
      });
    }

    _createClass(Statechart, [{
      key: 'start',
      value: function start() {
        var _this4 = this;

        this.currentState = this.machine.initialState;

        return this._executeActions(this.currentState).then(function () {
          return _this4.service.send('didInit');
        });
      }
    }, {
      key: 'send',
      value: function send(eventName) {
        var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        return this.service.send('send', { eventName: eventName, data: data });
      }
    }, {
      key: '_send',
      value: function _send(_ref3) {
        var eventName = _ref3.eventName,
            data = _ref3.data;

        var newState = this.machine.transition(this.currentState, { type: eventName, data: data }, this.context);

        Ember.set(this, 'currentState', newState);

        this.didChangeState(newState);

        return this._executeActions(newState, data);
      }
    }, {
      key: '_executeActions',
      value: function _executeActions(newState, data) {
        var _this5 = this;

        var actions = newState.actions;


        var _actions = actions.map(this._functionForAction.bind(this));

        var chain = _actions.reduce(function (acc, action) {
          return acc.then(function () {
            return action(data, _this5.context);
          });
        }, Ember.RSVP.resolve());

        return chain;
      }
    }, {
      key: '_functionForAction',
      value: function _functionForAction(action) {
        var fn = action.exec && action.exec.bind(this.context) || noOp;
        return fn;
      }
    }]);

    return Statechart;
  }();

  exports.default = Statechart;
});